import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-lb4bdownload',
  templateUrl: './lb4bdownload.component.html',
  styleUrls: ['./lb4bdownload.component.scss']
})
export class Lb4bdownloadComponent implements OnInit {
  lb4bdownload:any={}
  brandName:string
  
  constructor(private route: ActivatedRoute,private apiService: ApiService,private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      console.log(params); 
      this.apiService.WhProcCustomGetFactory('LB4_Merchant', 'getMerchantSocialLinks', {store :params.uniqueUrlId}).subscribe(merchantInfo=>{
        console.log(merchantInfo)
       // var storeURL = merchantInfo[0].buy_URL.split('/cart')[0];
			// vm.shopDomain = checkHttpPrefix(merchantInfo[0].domain);
		//	this.lb4bdownload.shopDomain = checkHttpPrefix(storeURL);
			this.lb4bdownload.socialMediaLinks = merchantInfo[0].social_links
			this.lb4bdownload.appAR_Image = merchantInfo[0].app_ar_image_url;
			this.lb4bdownload.searchImage = merchantInfo[0].app_search_image_url;
			this.lb4bdownload.storeName = merchantInfo[0].name;
			this.lb4bdownload.isTagLineAvail = false;
      console.log("30",merchantInfo)
			if(merchantInfo[0].mobile_app_type == 'Both' || merchantInfo[0].mobile_app_type == 'Private'){
				this.lb4bdownload.brandName = merchantInfo[0].name;
				this.lb4bdownload.privateApp = true;
			} else {
				this.lb4bdownload.brandName = 'LIVEb4buy';
				this.lb4bdownload.privateApp = false;
			}
      this.brandName = this.lb4bdownload.brandName
      console.log( " this.brandName",this.brandName)
			if(merchantInfo[0].store_tag_line){
				this.lb4bdownload.isTagLineAvail = true;
				this.lb4bdownload.storeTagLine = merchantInfo[0].store_tag_line;
			}
			if(merchantInfo[0].store_ios_link){
				this.lb4bdownload.iosLink = merchantInfo[0].store_ios_link;
			} else {
				this.lb4bdownload.iosLink = merchantInfo[0].lb4_application.lb4b_ios_link;
			}
			if(merchantInfo[0].store_android_link){
				this.lb4bdownload.androidLink = merchantInfo[0].store_android_link;
			} else {
				this.lb4bdownload.androidLink = merchantInfo[0].lb4_application.lb4b_android_link;
			}
			if(merchantInfo[0].qr_code_url){
				this.lb4bdownload.qrCodeURL = merchantInfo[0].qr_code_url;
			} else {
				this.lb4bdownload.qrCodeURL = merchantInfo[0].lb4_application.lb4b_qr_code_url;
			}
			if(merchantInfo[0].demo_video_url){
			    this.lb4bdownload.demoVideoURL =this.sanitizer.bypassSecurityTrustResourceUrl(merchantInfo[0].demo_video_url);
			
			} else {
				this.lb4bdownload.demoVideoURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/F8K8Xxu0Yxw');
			}
			this.lb4bdownload.merchantInfoReceived = true;
			console.log(this.lb4bdownload.demoVideoURL)

      })
     
    }
  );
  }

  transform(url) {
	  console.log(url)
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

}
