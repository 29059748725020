const swal = require('sweetalert');
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEvent, HttpParams, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../../api.service';
import { CanActivate, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

    private urlParams;
	constructor( private activatedRoute: ActivatedRoute, private apiService: ApiService) { }
	getPartner(){
  		this.getUrlParams();
  		if(this.urlParams['uniqueUrlId']){
		  	const  params = new  HttpParams().set('uniqueUrlId', this.urlParams['uniqueUrlId']);
		    console.log("params in here is", params);
		    this.apiService.PartnerCustomGetFactory('LB4_Merchant', 'getMerchantPartner', params).subscribe(res=>{
		        console.log('this is response',res);
		    },
		    (err: HttpErrorResponse) => {
		        this.throwErrorSwal()
		        console.log('err.message', err.message);
		    });
  		}
	}
	getUrlParams(){
		this.activatedRoute.queryParams.subscribe(params => {
            console.log('params is', params); // Print the parameter to the console.
            this.urlParams = params;
        });
	}
	throwErrorSwal(){
        swal({
                    title: 'Error',
                    text: 'Please contact Admin',
                    icon: 'error',
                    buttons: {
                        confirm: {
                            text: 'OK',
                            value: true,
                            visible: true,
                            className: "swal-button swal-button--confirm",
                            closeModal: true
                        }
                    }
                }).then(() => {
                });
    }
	ngOnInit() {
  		console.log("here we are in partner");
  		this.getPartner();
	}

}
