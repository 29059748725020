import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class ApiService {
	multiMediaURL: string = environment.multiMediaEndpoint;
	partnerURL: string = environment.partnerEndpoint;
	shopuserURL: string = environment.shopuserEndpoint;
	whprocURL :string = environment.whprocEndpoint;
	apiURL:string = environment.apiEndpoint;

	constructor(private httpClient: HttpClient) {}
	public MultiMediaCustomGetFactory(controller: string, method: string, params: any){
		console.log("controller is", controller);
		console.log("method is", method);
		console.log("params is", params);
	    return this.httpClient.get(`${this.multiMediaURL}${controller}/${method}`,{params});
	}
	public PartnerCustomGetFactory(controller: string, method: string, params: any){
		console.log("controller is", controller);
		console.log("method is", method);
		console.log("params is", params);
	    return this.httpClient.get(`${this.partnerURL}${controller}/${method}`,{params});
	}
	public ApiCustomPostFactory(controller: string, method: string, params: any){
		console.log("controller is", controller);
		console.log("method is", method);
		console.log("params is", params);
	    return this.httpClient.post(`${this.apiURL}${controller}/${method}`, params);
	}
	// public ShopUserCustomGetTextFactory(controller: string, method: string, params: any){
	// 	console.log("controller is", controller);
	// 	console.log("method is", method);
	// 	console.log("params is", params);
	//     return this.httpClient.get(`${this.shopuserURL}${controller}/${method}`,{responseType: 'text', params: params});
	// }
	public ShopUserCustomGetFactory(controller: string, method: string, params: any,header:any){
		console.log("controller is", controller);
		console.log("method is", method);
		console.log("params is", params);
		// console.log("headers is", header);
	    return this.httpClient.get(`${this.shopuserURL}${controller}/${method}`,{headers: header,params:params});
	}
	public ShopUserCustomPostFactory(controller: string, method: string, params: any,header:any){
		console.log("controller is", controller);
		console.log("method is", method);
		console.log("params is", params);
	    return this.httpClient.post(`${this.shopuserURL}${controller}/${method}`, params,{headers: header});
	}


	public WhProcCustomGetFactory(controller: string, method: string, params: any){
		console.log("controller is s", this.whprocURL);
		console.log("method is", method);
		console.log("params is", params);
	    return this.httpClient.get(`${this.whprocURL}${controller}/${method}`,{params});
	}


	/*public MultiMediaSimpleGetFactory(controller: string){
		console.log("controller is", controller);
	    return this.httpClient.get(`${this.multiMediaURL}${controller}`);
	}
	public MultiMediaSimpleOneGetFactory(controller: string, data: object){
		console.log("controller is", controller);
	    return this.httpClient.get(`${this.multiMediaURL}${controller}`, data);
	}*/
}
