import { LayoutComponent } from '../layout/layout.component';

import { PartnerComponent } from './partner/partner/partner.component';
import { ShopperUserComponent } from './shopper-user/shopper-user/shopper-user.component';
import { ClientFormComponent } from './shopper-user/client-form/client-form.component';
import { ContactFormComponent } from './shopper-user/contact-form/contact-form.component';
import { Lb4bdownloadComponent } from './shopper-user/lb4bdownload/lb4bdownload.component';
export const routes = [

    { path: 'partner', component: PartnerComponent },
    { path: 'shopper-user', component: ShopperUserComponent },
    { path: 'client-form/:uniqueUrlId/:appName', component: ClientFormComponent },
    { path: 'contact-form/:uniqueUrlId/:appName', component: ContactFormComponent },
    { path: 'lb4bdownload', component: Lb4bdownloadComponent },
    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
        ]
    },
    { path: '**', redirectTo: 'home' }

];
