const swal = require('sweetalert');
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../../api.service';
import _ from "lodash";
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {

  textAvail: boolean = true;
  emailAvail: boolean = true;
  widthAvail: boolean = true;
  heightAvail: boolean = true;
  firstnameAvail: boolean = true;
  lastnameAvail: boolean = true;
  fileNameValue: any;
  fileName: any;
  fileType: any;
  chooseFile = 'Choose File';
  postParams: any;
  customFile: any;
  submitBtnDisabled: boolean = false;
  submitBtnText: string = 'Submit';
  successMessage: any;
  errorMessage: any;
  uniqueUrlId: any;
  appName: any;
  clientForm: FormGroup;
  termsCond: boolean = true;
  siteHeaders:any;
  myFiles: any[] = [];
  imageUrls: string[] = [];
  imageurl: string;
  addedFilesInfo;
constructor(private apiService: ApiService, private http: HttpClient, private router: ActivatedRoute) { }

  ngOnInit() {



    this.uniqueUrlId = this.router.snapshot.paramMap.get('uniqueUrlId');
    this.appName = this.router.snapshot.paramMap.get('appName');
    console.log("uniqueUrlId is", this.uniqueUrlId)

  }

  fileChange(event) {
    this.myFiles = [];
    for (var i = 0; i < event.target.files.length; i++) {
      this.myFiles.push(event.target.files[i]);
    }
    if (this.myFiles.length <= 0) {
      this.addedFilesInfo = "Choose File";
    } else if (this.myFiles.length == 1) {
      this.addedFilesInfo = this.myFiles.length + " file selected";
    } else {
      this.addedFilesInfo = this.myFiles.length + " files selected";
    }
    console.log("myfiles array:", this.myFiles);
    for (var i = 0; i < this.myFiles.length; i++) {
      console.log("value req:", this.myFiles[i]['name']);
    }


  }

  checktermsAndCond(e) {
    console.log("event is", e)
    this.termsCond = e.target.checked;
  }
  services = [
    {
      title: 'RetailFraming',
      enabled: false,
    },
    {
      title: 'Printing',
      enabled: false,
    },
    {
      title: 'CustomMirrors',
      enabled: false,
    },
    {
      title: 'CommercialFraming',
      enabled: false,
    },
  ];

  submitClicked(firstname, userEmail, userPhone, userText, width, height, services) {
    console.log("myfile name:", this.myFiles);
    if (!firstname || _.isEmpty(firstname)) {
      this.firstnameAvail = false;
    } else {
      this.firstnameAvail = true;
    }
    // if (!lastname || _.isEmpty(lastname)) {
    //   this.lastnameAvail = false;
    // } else {
    //   this.lastnameAvail = true;
    // }
    if (!userEmail || _.isEmpty(userEmail) || !(userEmail.indexOf('@')>-1)) {
      this.emailAvail = false;
    } else {
      this.emailAvail = true;
    }
    if (!userText || _.isEmpty(userText)) {
      this.textAvail = false;
    } else {
      this.textAvail = true;
    }
    if (!width || _.isEmpty(width)) {
      this.widthAvail = false;
    } else {
      this.widthAvail = true;
    }
    if (!height || _.isEmpty(height)) {
      this.heightAvail = false;
    } else {
      this.heightAvail = true;
    }

 


    if (this.textAvail && this.emailAvail) {
      this.postParams = {};
      // this.postParams['fileName'] = this.fileName;
      // this.postParams['fileType'] = this.fileType;
      // this.postParams['filePath'] = this.customFile;
      this.postParams['firstName'] = firstname;
      // this.postParams['lastName'] = lastname;
      this.postParams['email'] = userEmail;
      this.postParams['phone'] = userPhone;
      this.postParams['width'] = width;
      this.postParams['height'] = height;
      this.postParams['userText'] = userText;
      this.postParams['appName'] = this.appName;
      this.postParams['uniqueUrlID'] = this.uniqueUrlId;
      this.postParams['isAgree'] = this.termsCond;
      this.postParams['measurementUnit'] = 'inches';
      this.postParams['services'] = this.services;
      
      console.log("postParams is", this.postParams)
      this.submitBtnDisabled = true;
      this.submitBtnText = 'Please Wait...';
      // kctoken from api
      this.apiService.ApiCustomPostFactory('LARF_Keycloak', 'getShopKcToken', { uniqueUrlId:this.uniqueUrlId, appname: this.appName, }).subscribe(kctokenResponse => {
        // console.log("Response kctoken is", kctokenResponse)
        this.siteHeaders = {}
        this.siteHeaders['authorization'] = "Bearer " + kctokenResponse['access_token'];
        var t = JSON.parse(window.atob(kctokenResponse['access_token'].split('.')[1]))["iss"].split("/");
        this.siteHeaders['realmname'] = t[t.length - 1];
        if(!_.isEmpty(this.myFiles)){
          for (var i = 0; i < this.myFiles.length; i++) {
            this.multiUpload(this.myFiles[i],i+1);
          }
        }else{
          this.apiService.ShopUserCustomPostFactory('User_Requirement', 'createCustomArtRequirement', this.postParams, this.siteHeaders).subscribe(res => {
            console.log('this is response', res);
            this.submitBtnDisabled = false;
            this.submitBtnText = 'Submit';
            // this.clientForm.reset();
            if (_.isEmpty(res)) {
              this.errorMessage = 'Error in submitting the form, Please contact Admin'
              this.throwErrorSwal(this.errorMessage)
            } else {
              this.successMessage = 'Your Request has submitted successfully'
              this.successSwal(this.successMessage)
            }
          },
            (err: HttpErrorResponse) => {
              // this.loaderImage = false;
              this.errorMessage = 'Error in submitting the form, Please contact Admin';
              this.throwErrorSwal(this.errorMessage)
              console.log('err.message', err.message);
            });
        }
      })
    }


  }

  throwErrorSwal(errorMessage) {
    swal({
      title: 'Error',
      text: errorMessage,
      icon: 'error',
      buttons: {
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: "swal-button swal-button--confirm",
          closeModal: true
        }
      }
    }).then(() => {
    });
  }
  successSwal(successMessage) {
    swal({
      title: 'Success',
      text: successMessage,
      icon: 'success',
      buttons: {
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: "swal-button swal-button--confirm",
          closeModal: true
        }
      }
    }).then(() => {
    });
  }

  uploadfileAWSS3(fileuploadurl, contenttype, file) {
    console.log("fileuploadurl", fileuploadurl)
    console.log("contenttype", contenttype)
    console.log("file", file)
    const headers = new HttpHeaders({ 'Content-Type': contenttype, 'Access-Control-Allow-Origin': '*' });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
      });
    return this.http.request(req);
  }
  multiUpload(file, index) {
    console.log("file name is", file.name)
    console.log("file type is", file.type)
    this.apiService.ShopUserCustomGetFactory('LB4_Merchant', 'getS3PreUploadUrl', { imageName: file.name, uniqueUrlID: this.uniqueUrlId }, this.siteHeaders).subscribe(apiResponse => {
      this.uploadfileAWSS3(apiResponse['url'], file.type, file).subscribe(data => {
        console.log('data is for put', data)
        if (data['status'] == 200) {
          this.imageUrls.push("https://lb4b-dev-bucket.s3.amazonaws.com/shopperuser/" + this.uniqueUrlId + "/" + file.name);
          console.log('postparams urldata', this.imageUrls);
          console.log('index', index);
          console.log('this.myFiles.length', this.myFiles.length);
          if (index == this.myFiles.length) {
            this.postParams['imageURLS'] = this.imageUrls;
            console.log('postparams data', this.postParams)
            this.apiService.ShopUserCustomPostFactory('User_Requirement', 'createCustomArtRequirement', this.postParams, this.siteHeaders).subscribe(res => {
              console.log('this is response', res);
              this.submitBtnDisabled = false;
              this.submitBtnText = 'Submit';
              // this.clientForm.reset();
              if (_.isEmpty(res)) {
                this.errorMessage = 'Error in submitting the form, Please contact Admin'
                this.throwErrorSwal(this.errorMessage)
              } else {
                this.successMessage = 'Your Request has submitted successfully'
                this.successSwal(this.successMessage)
              }
            },
              (err: HttpErrorResponse) => {
                // this.loaderImage = false;
                this.errorMessage = 'Error in submitting the form, Please contact Admin';
                this.throwErrorSwal(this.errorMessage)
                console.log('err.message', err.message);
              });
          }
        }
      });
    })
  }
}

