import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { AgmCoreModule } from '@agm/core';

import { SharedModule } from '../../shared/shared.module';
import { PartnerComponent } from './partner/partner.component';

const routes: Routes = [
    // { path: '', component: PartnerComponent }
];

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(routes),
        /*AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBNs42Rt_CyxAqdbIBK0a5Ut83QiauESPA'
        })*/
    ],
    declarations: [PartnerComponent],
    exports: [
        RouterModule
    ]
})
export class PartnerModule { }
