const swal = require('sweetalert');
import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { ApiService } from '../../../api.service';
import _ from "lodash";
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss']
})
export class ClientFormComponent implements OnInit {

  textAvail: boolean = true;
  emailAvail: boolean = true;
  widthAvail: boolean = true;
  heightAvail: boolean = true;
  firstnameAvail: boolean = true;
  lastnameAvail: boolean = true;
  fileNameValue: any;
  fileName: any;
  fileType: any;
  chooseFile = 'Choose File';
  postParams: any;
  customFile: any;
  submitBtnDisabled: boolean = false;
  submitBtnText: string = 'Submit';
  successMessage: any;
  errorMessage: any;
  uniqueUrlId: any;
  appName: any;
  clientForm: FormGroup;
  termsCond: boolean = true;
  siteHeaders:any;


  constructor(private apiService: ApiService, private http: HttpClient, private router: ActivatedRoute) { }

  ngOnInit() {
    this.uniqueUrlId = this.router.snapshot.paramMap.get('uniqueUrlId');
    this.appName = this.router.snapshot.paramMap.get('appName');
    console.log("uniqueUrlId is", this.uniqueUrlId)
  }

  fileChange(file) {
    this.fileName = file.target.files[0].name;
    this.fileType = file.target.files[0].type;
    this.customFile = file.target.files[0]
    console.log("file name is", this.fileName)
    console.log("file type is", this.fileType)
    this.chooseFile = this.fileName
  }

  checktermsAndCond(e) {
    console.log("event is", e)
    this.termsCond = e.target.checked;
  }

  submitClicked(firstname, lastname, userEmail, userPhone, userText, width, height) {
    console.log("response customfile", this.customFile)
    if (!firstname || _.isEmpty(firstname)) {
      this.firstnameAvail = false;
    } else {
      this.firstnameAvail = true;
    }
    if (!lastname || _.isEmpty(lastname)) {
      this.lastnameAvail = false;
    } else {
      this.lastnameAvail = true;
    }
    if (!userEmail || _.isEmpty(userEmail)) {
      this.emailAvail = false;
    } else {
      this.emailAvail = true;
    }
    if (!userText || _.isEmpty(userText)) {
      this.textAvail = false;
    } else {
      this.textAvail = true;
    }
    if (!width || _.isEmpty(width)) {
      this.widthAvail = false;
    } else {
      this.widthAvail = true;
    }
    if (!height || _.isEmpty(height)) {
      this.heightAvail = false;
    } else {
      this.heightAvail = true;
    }

    if (this.textAvail && this.emailAvail) {
      this.postParams = {};
      // this.postParams['fileName'] = this.fileName;
      // this.postParams['fileType'] = this.fileType;
      // this.postParams['filePath'] = this.customFile;
      this.postParams['firstName'] = firstname;
      this.postParams['lastName'] = lastname;
      this.postParams['email'] = userEmail;
      this.postParams['phone'] = userPhone;
      this.postParams['width'] = width;
      this.postParams['height'] = height;
      this.postParams['userText'] = userText;
      this.postParams['appName'] = this.appName;
      this.postParams['uniqueUrlID'] = this.uniqueUrlId;
      this.postParams['isAgree'] = this.termsCond;
      this.postParams['measurementUnit'] = 'inches';
      console.log("postParams is", this.postParams)
      this.submitBtnDisabled = true;
      this.submitBtnText = 'Submitted';
      // kctoken from api
      this.apiService.ApiCustomPostFactory('LARF_Keycloak', 'getShopKcToken', { uniqueUrlId: this.uniqueUrlId, appname: this.appName, }).subscribe(kctokenResponse => {
        // console.log("Response kctoken is", kctokenResponse)
        var siteHeaders = {}
        siteHeaders['authorization'] = "Bearer " + kctokenResponse['access_token'];
        var t = JSON.parse(window.atob(kctokenResponse['access_token'].split('.')[1]))["iss"].split("/");
        siteHeaders['realmname'] = t[t.length - 1];
        // console.log("token header",siteHeaders);

        this.apiService.ShopUserCustomGetFactory('LB4_Merchant', 'getS3PreUploadUrl', { imageName: this.fileName, uniqueUrlID: this.uniqueUrlId},siteHeaders).subscribe(apiResponse => {
          console.log("apiResponse is", apiResponse)

          this.uploadfileAWSS3(apiResponse['url'], this.fileType, this.customFile).subscribe(data => {
            console.log('data is for put', data)
            if (data['status'] == 200) {
              this.postParams['imageURL'] = "https://lb4b-dev-bucket.s3.amazonaws.com/shopperuser/" + this.uniqueUrlId + "/" + this.fileName;
              console.log('postparams data', this.postParams)

              this.apiService.ShopUserCustomPostFactory('User_Requirement', 'createCustomArtRequirement', this.postParams,siteHeaders).subscribe(res => {
                console.log('this is response', res);
                this.submitBtnDisabled = false;
                this.submitBtnText = 'Submit';
                // this.clientForm.reset();
                if (_.isEmpty(res)) {
                  this.errorMessage = 'Error in submitting the form, Please contact Admin'
                  this.throwErrorSwal(this.errorMessage)
                } else {
                  this.successMessage = 'Your Request has submitted successfully'
                  this.successSwal(this.successMessage)
                }
              },
                (err: HttpErrorResponse) => {
                  // this.loaderImage = false;
                  this.errorMessage = 'Error in submitting the form, Please contact Admin';
                  this.throwErrorSwal(this.errorMessage)
                  console.log('err.message', err.message);
                });

            }
          });
        })
      })
    }


  }

  throwErrorSwal(errorMessage) {
    swal({
      title: 'Error',
      text: errorMessage,
      icon: 'error',
      buttons: {
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: "swal-button swal-button--confirm",
          closeModal: true
        }
      }
    }).then(() => {
    });
  }
  successSwal(successMessage) {
    swal({
      title: 'Success',
      text: successMessage,
      icon: 'success',
      buttons: {
        confirm: {
          text: 'OK',
          value: true,
          visible: true,
          className: "swal-button swal-button--confirm",
          closeModal: true
        }
      }
    }).then(() => {
    });
  }

  uploadfileAWSS3(fileuploadurl, contenttype, file) {
    console.log("fileuploadurl", fileuploadurl)
    console.log("contenttype", contenttype)
    console.log("file", file)
    const headers = new HttpHeaders({ 'Content-Type': contenttype, 'Access-Control-Allow-Origin': '*' });
    const req = new HttpRequest(
      'PUT',
      fileuploadurl,
      file,
      {
        headers: headers,
      });
    return this.http.request(req);
  }


}
