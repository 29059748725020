import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ShopperUserComponent } from './shopper-user/shopper-user.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import {FileUploadModule} from 'ng2-file-upload';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { Lb4bdownloadComponent } from './lb4bdownload/lb4bdownload.component';

const routes: Routes = [
    // { path: '', component: PartnerComponent }
    // { path: 'client-form', component: ClientFormComponent }

];

@NgModule({
  imports: [
    SharedModule,
        RouterModule.forChild(routes),
    FileUploadModule,
    FormsModule
  ],
  declarations: [ShopperUserComponent, ClientFormComponent, ContactFormComponent, Lb4bdownloadComponent],
  exports: [
        RouterModule
    ]
})
export class ShopperUserModule { }
