// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // multiMediaEndpoint: "https://liveb4buy.ngrok.io/lb4b/multimedia/v1/"
  multiMediaEndpoint: "https://multimedia.liveb4buy.com/lb4b/multimedia/v1/",
  // partnerEndpoint: "https://partner.liveb4buy.com/lb4b/partner/v1/",
  whprocEndpoint: "https://whproc.liveb4buy.com/lb4b/whproc/v1/",
  partnerEndpoint: "https://liveb4buy.ngrok.io/lb4b/partner/v1/",
  shopuserEndpoint: "https://lb4b-shopper-user.liveb4buy.com/lb4b/shopperuser/v1/",
  apiEndpoint: "https://lb4bapi.liveb4buy.com/lb4b/api/v1/"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
