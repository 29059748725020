const swal = require('sweetalert');
import { Component, OnInit } from '@angular/core';
import { CanActivate, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders, HttpEvent, HttpParams, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { ApiService } from '../../../api.service';

import _ from "lodash";

@Component({
	selector: 'app-shopper-user',
	templateUrl: './shopper-user.component.html',
	styleUrls: ['./shopper-user.component.scss']
})
export class ShopperUserComponent implements OnInit {

	constructor(private http: HttpClient, private activatedRoute: ActivatedRoute, private apiService: ApiService) { }
	errorMessage: string;
	roomOptions: Array<any>;
	themeOptions: Array<any>;
	textAvail: boolean = true;
	emailAvail: boolean = true;
	merchantId: string;
	successMessage: string;
	uniqueUrlID: string;
	siteHeaders:any;
	loadData() {
		this.activatedRoute.queryParams.subscribe(params => {
			console.log('params is', params); // Print the parameter to the console.
			if (_.has(params, 'uniqueUrlId') && !_.isEmpty(params['uniqueUrlId'])) {
				console.log("uniqueUrlId is", params['uniqueUrlId']);
				this.uniqueUrlID = params['uniqueUrlId'];
				var merchantGetParams = {};
				merchantGetParams['uniqueUrlID'] = params['uniqueUrlId']
				// merchantGetParams['uniqueUrlID'] = 'liveb4buy_myshopifys'
				// kctoken from api
				this.apiService.ApiCustomPostFactory('LARF_Keycloak', 'getShopKcToken', { uniqueUrlId: params['uniqueUrlId'] }).subscribe(kctokenResponse => {
					// console.log("Response kctoken is", kctokenResponse)
					var siteHeaders = {}
					siteHeaders['authorization'] = "Bearer " + kctokenResponse['access_token'];
					var t = JSON.parse(window.atob(kctokenResponse['access_token'].split('.')[1]))["iss"].split("/");
					siteHeaders['realmname'] = t[t.length - 1];
					// console.log("token header",siteHeaders);

					this.apiService.ShopUserCustomGetFactory('LB4_Merchant', 'getMerchantInfo', merchantGetParams,siteHeaders).subscribe(res => {
						console.log('this is response', res);
						if (_.isEmpty(res)) {
							this.errorMessage = 'No store found'
							this.throwErrorSwal(this.errorMessage)
						} else {
							this.merchantId = res[0]['id'];
							this.roomOptions = [
								'-Select-',
								'Kid\'s Room',
								'Living Room',
								'Office/Study Room',
								'Bedroom',
								'Kitchen and Dinning Room'
							];
							this.themeOptions = [
								'-Select-',
								'Nature/Landscape',
								'Meditative',
								'Spiritual/Religious',
								'World Traveller',
								'Superheroes/Characters',
								'Others'
							]
						}
					},
						(err: HttpErrorResponse) => {
							// this.loaderImage = false;
							this.errorMessage = 'Error in get store info';
							this.throwErrorSwal(this.errorMessage)
							console.log('err.message', err.message);
						});
				});
			} else {
				console.log("no params['uniqueUrlId'] found");
				this.errorMessage = 'No shop found without data, Please contact Admin'
				this.throwErrorSwal(this.errorMessage)
			}
		});
	}
	submitCLicked(userText, email, name, phone, roomType, themeType) {
		console.log("in submit");
		if (!userText || _.isEmpty(userText)) {
			this.textAvail = false;
		} else {
			this.textAvail = true;
		}
		if (!email || _.isEmpty(email)) {
			this.emailAvail = false;
		} else {
			this.emailAvail = true;
		}
		if (this.textAvail && this.emailAvail) {
			if (roomType == '-Select-') {
				roomType = ""
			}
			if (themeType == '-Select-') {
				themeType = ""
			}
			var postParams = {};
			postParams['merchantID'] = this.merchantId;
			// postParams['uniqueUrlID'] = this.uniqueUrlID;
			postParams['name'] = name;
			postParams['phone'] = phone;
			postParams['room_type'] = roomType;
			postParams['theme_style'] = themeType;
			postParams['email'] = email;
			postParams['requirement'] = userText;
			this.apiService.ShopUserCustomPostFactory('User_Requirement', 'createUserRequirement', postParams,this.siteHeaders).subscribe(res => {
				console.log('this is response', res);
				if (_.isEmpty(res)) {
					this.errorMessage = 'Error in submitting the form, Please contact Admin'
					this.throwErrorSwal(this.errorMessage)
				} else {
					this.successMessage = 'Your Request has submitted successfully'
					this.successSwal(this.successMessage)
				}
			},
				(err: HttpErrorResponse) => {
					// this.loaderImage = false;
					this.errorMessage = 'Error in submitting the form, Please contact Admin';
					this.throwErrorSwal(this.errorMessage)
					console.log('err.message', err.message);
				});
		}
		console.log("userText in submit", userText);
		console.log("email in submit", email);
		console.log("name in submit", name);
		console.log("phone in submit", phone);
		console.log("roomType in submit", roomType);
		console.log("themeType in submit", themeType);
	}
	throwErrorSwal(errorMessage) {
		swal({
			title: 'Error',
			text: errorMessage,
			icon: 'error',
			buttons: {
				confirm: {
					text: 'OK',
					value: true,
					visible: true,
					className: "swal-button swal-button--confirm",
					closeModal: true
				}
			}
		}).then(() => {
		});
	}
	successSwal(successMessage) {
		swal({
			title: 'Success',
			text: successMessage,
			icon: 'success',
			buttons: {
				confirm: {
					text: 'OK',
					value: true,
					visible: true,
					className: "swal-button swal-button--confirm",
					closeModal: true
				}
			}
		}).then(() => {
		});
	}

	ngOnInit() {
		this.loadData();
	}

}
